//FIX Flashing and wrong size font awesome icons on first load
require('@fortawesome/fontawesome-svg-core/styles.css')
const config = require('@fortawesome/fontawesome-svg-core')
config.autoAddCss = false

const isPathWithLangPrefix = (languages, pathname) => {
    return languages.some((lang) => pathname.startsWith(`/${lang}/`))
}

exports.wrapPageElement = ({element, props}) => {
    const {pageContext, location} = props || {}
    const i18n = pageContext?.i18n

    if (!i18n || typeof window === 'undefined' || i18n.routed) {
        return
    }
    const {language, languages, defaultLanguage} = i18n
    const {pathname, search, hash} = location
    const detectedLang = languages.includes(language) ? language : defaultLanguage

    if (!isPathWithLangPrefix(languages, pathname)) {
        window.___replace(`/${detectedLang}${pathname}${search}${hash}`)
    }
    return null
}
